import React, { useState } from 'react'
import './UpdatePayMove.scss'
import { database } from '../../FirebaseConfig/config'
import { collection, doc, updateDoc } from 'firebase/firestore'
import CommonAlert from '../CommonAlert/CommonAlert'
import close from '../../Assets/close.png'

function UpdatePayAndMove(props) {
    const useData = JSON.parse(props.userVal)
    const mTCol = doc(database,localStorage.getItem("uid"),'mT')
    const valueDb = collection(mTCol,'list')
    const docRef =  doc(valueDb, useData.id);
    const [interNet,setInterNet] = useState(false)

    const handlePay =async(e)=>{
        e.preventDefault()
        if(e.target.damount.value !==''){
            let existingVal = useData.payments
            // formate data 
            var currentDate = new Date();
            var year = currentDate.getFullYear();
            var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            var day = currentDate.getDate().toString().padStart(2, '0');
            var formattedDate = year + '-' + month + '-' + day;

            if(useData.isEmi){
                existingVal.push({amount:e.target.damount.value,paidDate:JSON.stringify(formattedDate)})
            }
            if (navigator.onLine) {
            try{
                props.hide(false)
                await updateDoc(docRef, {
                    pAmount: Number(useData.pAmount)+Number(e.target.damount.value),
                    payments:existingVal,
                });
            }catch(e){
                console.log("error",e)
            }
            }else{
                setInterNet(true)
            }
        }
    }
    
    const handleMove =async(type)=>{
        if (navigator.onLine) {
        if(props.from == 'member'){
            try{
                props.hide(false)
                await updateDoc(docRef,{
                    category:type == 'C'?'N':'C'
                })
            }catch(e){
                console.log(e,"error")
            }
        }else if(props.from == 'checkIn'){
            try{
                props.hide(false)
                await updateDoc(docRef,{
                    type: 'M'
                })   
              }catch(e){
                  console.log(e,"error")
              }
        }else{
            try{
                props.hide(false)
                await updateDoc(docRef,{
                    isActiveTrainer: type == 'Y'?'N':'Y'
                })   
              }catch(e){
                  console.log(e,"error")
              }
        }
        }else {
            setInterNet(true)
            console.log('No internet connection.');
        }
    }

  return (
    <div>
        <div className='updatePay'>
            {props.type == 'pay' ?
            <div className='paymentPopup'>
                <button className='companyCross' onClick={()=>props.hide(false)}><img src={close} height="13px" alt="cloe the payment popup"/></button>
                <div className='details'>
                    <p className='title'>Pay Due Amount</p>
                    <div>
                        <p>Name:<span> {useData.name}</span></p>
                        <p>Total amount:<span>  {useData.tAmount}</span></p>
                        <p>paid amount:<span> {useData.pAmount}</span></p>
                        <form className='paymentForm' onSubmit={(e)=>handlePay(e)}>
                            <input type='number' name='damount' max={useData.tAmount-useData.pAmount} placeholder='Due Amount' />
                            <button>Pay</button>
                        </form>
                    </div>
                </div>
            </div>:""}
            {props.type == 'move' || props.type == 'cMove' || props.type =='moveCheckIn'?
            <div className='movePopup'>
                <button className='companyCross' onClick={()=>props.hide(false)}><img src={close} height="13px" alt="cloe the move member or trainer popup"/></button>
                <div className='details'>
                    {
                        props.from == 'checkIn'?
                        <p className='movePopupDetail'>Do you want to Convert<span style={{color:'var(--active-title)',padding:'0 5px'}}>{useData.name}</span> to a Member</p>:
                        <p className='movePopupDetail'>Do you want to Change<span style={{color:'var(--active-title)',padding:'0 5px'}}>{useData.name}</span> status to {props.from == 'member'? (useData.category == 'N'?'Completed':'New'):(useData.isActiveTrainer == 'Y'?'InActive':'Active')}</p>
                    }
                    <div className='moveBtn'>
                        <button onClick={()=>handleMove(props.from == 'member'? useData.category:useData.isActiveTrainer)}>Move</button>
                        <button onClick={()=>props.hide(false)}>Cancel</button>
                    </div>
                </div>
            </div>:""}
        </div>
        {
            interNet?
            <CommonAlert msg="No Internet" hide={setInterNet} />:''
        }
    </div>
  )
}

export default UpdatePayAndMove