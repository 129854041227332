import React, { useState } from 'react'
import './Payments.scss'
import SideMenu from '../Component/SideMenu/SideMenu'
import UserTable from '../Component/UserTable/UserTable'
import RegistrationPopUp from '../Component/RegisterForm/RegisterForm'
import { Helmet } from 'react-helmet'

function Payments() {
  const [showRegister,setShowRegister] = useState(false)

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Payments</title>
          <meta name="description" content="Easily manage your gym's financial records with the Fitmi Studios dashboard. Track transactions, filter payments by date or member, and ensure accurate financial management for efficient oversight and planning." />
          <meta name="og:title" property="og:title" content="Fitmi Studios Payments | Transaction History and Account Management"/>
          <meta property="og:url" content="https://www.fitmistudios.com/payments" />
          <meta name="og:description" property="og:description" content="Manage your gym's transactions effortlessly with Fitmi Studios. Filter payments by date and name for precise financial oversight and effective account management."/>
          <meta name="keywords" content="fitmi studios, payment management, gym transactions, filter payments, track payments, financial management, account management, gym financial planning" />
          <link rel="canonical" href="https://www.fitmistudios.com/payments" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Fitmi Studios Payments",
              "description": "Track and manage your gym's financial transactions with the Fitmi Studios dashboard. Filter payments by date and member for accurate financial management and planning.",
              "url": "https://www.fitmistudios.com/payments"
            }`}
          </script>
      </Helmet>
      <div className='pageSplit'>
        <div>
          <SideMenu/>
        </div>
        <div className='mainContainer'>
        <div className='nameBox'>
          <span className='pageTitle'>Payments</span>
        </div>
          {
            showRegister?
            <RegistrationPopUp from="payment" hide={setShowRegister}/>:''
          }
          <UserTable from="payment" />
        </div>
      </div>
    </div>
  )
}

export default Payments