import React, { useEffect, useState } from 'react'
import './Equipment.scss'
import { auth, database } from '../../FirebaseConfig/config'
import { addDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import close from '../../Assets/close.png'
import CommonAlert from '../CommonAlert/CommonAlert'

function Equipment(props) {
    const [equipmentList,setEquipment]=useState([{eName:"",eWeight:"",eCount:""}]) 
    const [uid,setUid] = useState('')
    const [equipEditId,setEquipEditId] = useState('')
    const [cData,setCData] = useState([])
    const [interNet,setInterNet] = useState(false)
    const [details,setDetails] = useState({
        cNameId:'',
        cName:'',
        cMsgId:'',
        cMsg:'',
        tMessage:''
    })

    const handleClick=()=>{
        setEquipment([...equipmentList,{eName:"",eWeight:"",eCount:""}])
    }

    const handleChange=(e,i)=>{
        const {name,value}=e.target;
        const onchangeVal = [...equipmentList]
        if(name === 'eName'){
            onchangeVal[i][name]=value;
        }
        if(name === 'eWeight'){
            onchangeVal[i][name]=value;
        }
        if(name === 'eCount'){
            onchangeVal[i][name]=value;
        }
        setEquipment(onchangeVal)
    }

    const handleDelete=(i)=>{
        const deleteVal = [...equipmentList]
        deleteVal.splice(i,1)
        setEquipment(deleteVal)
    }

    useEffect(()=>{
        auth.onAuthStateChanged((existingUser)=>{
            try {
                let existingUid = existingUser.uid?existingUser.uid:existingUser.providerData[0].uid
                setUid(existingUid)
                getDocuments(existingUid)
            } catch (error) {
                console.log(error)
            }
           })

           const getDocuments =async(uid)=>{
               const equipmentCol = doc(database,uid,'equipment')
               const valueDb = collection(equipmentCol,'list')
               const getEquipmentList = await getDocs(valueDb)
            
                if (!getEquipmentList.empty) {
                    const data = getEquipmentList.docs[0].data();
                    setEquipEditId(getEquipmentList.docs[0].id);
                    setEquipment(JSON.parse(data.eList));
                }
            }
       },[])
    useEffect(()=>{
       },[uid])


    const handleSaveEquipment = async(e) =>{
        e.preventDefault()
            const equipmentCol = doc(database,uid,'equipment')
            const valueDb = collection(equipmentCol,'list')
            let updateVal;
            if(equipEditId){
              updateVal =  doc(valueDb, equipEditId);
            }
            if (navigator.onLine) {
                try{
                  props.show(false)
                  await (equipEditId?updateDoc:addDoc)(equipEditId?updateVal:valueDb,{
                      eList:JSON.stringify(equipmentList),
                      created:new Date()
                    })   
                }catch(e){
                    console.log(e,"error")
                }
            }else {
                setInterNet(true)
                console.log('No internet connection.');
            }
      }


    //   profile code
    
      useEffect(()=>{
        const getCompanyDetails = async()=>{
          const commonCollection = doc(database,localStorage.getItem("uid"),'common')
          const companyData = await getDocs(collection(commonCollection,'companyDetails'))
          const companyDetails = companyData.docs.map((doc)=>({...doc.data(),id:doc.id}))
          setCData(companyDetails)
        }
        getCompanyDetails()
      },[])
    
      useEffect(()=>{
        setDetails({...details,
          cName:cData[0]?.cName,
          cNameId:cData[0]?.id,
          cMsg:cData[0]?.message,
          tMessage:cData[0]?.tMessage
        })
      },[cData])
      const handleSubmit = async(e) =>{
        e.preventDefault()
        let commonCollection;
        let valueDb;
        let updateVal;
        if (navigator.onLine) {
             commonCollection = doc(database,localStorage.getItem("uid"),'common')
             valueDb = collection(commonCollection,'companyDetails')
             updateVal =  doc(valueDb, details.cNameId);
        }
        const data = {
          cName:details.cName,
          message:details.cMsg,
          tMessage:details.tMessage,
          updated:new Date(),
        }
        if (navigator.onLine) {
            try{
                props.show(false)
                await updateDoc(updateVal,data)
            }catch(e){
                console.log(e,"error")
            }
        }else {
            setInterNet(true)
            console.log('No internet connection.');
        }
      }

  return (
    <div>
        <div className='equipmentPopupBg'>
            <div className='equipmentPopup'>
                <button className='cloaseButton' onClick={()=>{props.show(false)}}> <img src={close} height="13px" alt="cloe the equipment popup"/> </button>
                {props.from == 'equipment'?
                <div>
                    <div className='equipmentTitle'>Add Equipment List</div><br/>
                    <button  className="equipmentBtn" onClick={handleClick}>Add</button>
                <div className='equipmentHeader'>
                    <div>Equipment Name</div>
                    <div>weight</div>
                    <div>Count</div>
                </div>
                <div className='equipmentContainer'>
                    {
                        equipmentList.map((val,i)=>
                        <div key={i} className="equipmentList">
                            <input className="eName" name="eName" value={val.eName} onChange={(e)=>handleChange(e,i)} placeholder="Equipment Name" />
                            <input type='number' className="eBtnSize eWeight" name="eWeight" value={val.eWeight} onChange={(e)=>handleChange(e,i)} placeholder="Weight" />
                            <input type='number' className="eBtnSize eCount" name="eCount" value={val.eCount} onChange={(e)=>handleChange(e,i)} placeholder="Count" />
                            <div className="removeInput">
                                <button  className="deleteEquipment" onClick={()=>handleDelete(i)}><img src={close} height="11px" alt="cloe the equipment popup" /></button>
                            </div>
                        </div>
                        )
                    }
                </div>
                <button className='equipmentBtn' onClick={handleSaveEquipment}>Save</button>
                </div>
                :
                <div>
                    <div className='equipmentTitle'>Profile</div><br/>
                    <div>
                        <form className='settingsForm' onSubmit={(e)=>handleSubmit(e)}>
                        <div>
                            <span>Company Name</span><br/>
                            <input name='cName' value={details.cName} onChange={(e)=>setDetails({...details,cName:e.target.value})}></input>
                        </div>
                        <div className='msgContainer'>
                            <span>Common Message to Member</span><br/>
                            <textarea name='msg' value={details.cMsg} onChange={(e)=>setDetails({...details,cMsg:e.target.value})}></textarea>
                        </div>
                        <div className='msgContainer'>
                            <span>Common Message to Trainer</span><br/>
                            <textarea name='msg' value={details.tMessage} onChange={(e)=>setDetails({...details,tMessage:e.target.value})}></textarea>
                        </div>
                        <br/>
                        <div>
                            <button>Save</button>
                        </div>
                        </form>
                    </div>
                </div>
                }
            </div>
        </div>
        {
            interNet?
            <CommonAlert msg="No Internet" hide={setInterNet} />:''
        }
    </div>
  )
}

export default Equipment