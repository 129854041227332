import React, { useState } from 'react'
import './Trainers.scss'
import SideMenu from '../Component/SideMenu/SideMenu'
import RegistrationPopUp from '../Component/RegisterForm/RegisterForm'
import UserTable from '../Component/UserTable/UserTable'
import { Helmet } from 'react-helmet'


function Trainers() {
  const [showRegister,setShowRegister] = useState(false)

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Trainers</title>
          <meta name="description" content="Manage trainer profiles easily with the Fitmi Studios dashboard. Add, edit, or delete trainers, update their status, and export trainer information to PDF. Filter trainers by date, status, or name for efficient gym operations." />
          <meta name="og:title" property="og:title" content="Fitmi Studios Trainers | Trainer Profile Management"/>
          <meta property="og:url" content="https://www.fitmistudios.com/trainers" />
          <meta name="og:description" property="og:description" content="Effortlessly manage your trainers with Fitmi Studios. Add, edit, or delete trainer profiles, toggle status, and export details to PDF. Filter by date, status, or name for streamlined management."/>
          <meta name="keywords" content="fitmi studios, trainer management, gym trainers, add trainers, export trainers, filter trainers, active trainers, inactive trainers, gym management" />
          <link rel="canonical" href="https://www.fitmistudios.com/trainers" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Fitmi Studios Trainers",
              "description": "Easily manage your gym trainers with the Fitmi Studios dashboard. Add, edit, and delete trainer profiles, toggle their status, and export their details to PDF. Filter trainers by date, status, or name for better gym management.",
              "url": "https://www.fitmistudios.com/trainers"
            }`}
          </script>
      </Helmet>
      <div className='pageSplit'>
        <div>
          <SideMenu/>
        </div>
        <div className='mainContainer'>
        <div className='nameBox'>
          <span className='pageTitle'>Trainers</span>
        </div>
          <button className='RegisterBtn' onClick={()=>setShowRegister(true)}>Add New Trainer</button>
          <div className='mobileReg' onClick={()=>setShowRegister(true)}>+</div>
          {
            showRegister?
            <RegistrationPopUp from="trainer" hide={setShowRegister}/>:''
          }
          <UserTable from="trainer" reRenderData={showRegister} />
        </div>
      </div>
    </div>
  )
}

export default Trainers