import React, { useState } from 'react'
import '../Members/Members.scss'
import SideMenu from '../Component/SideMenu/SideMenu'
import RegistrationPopUp from '../Component/RegisterForm/RegisterForm'
import UserTable from '../Component/UserTable/UserTable'
import { Helmet } from 'react-helmet'

function Leads() {
  const [showRegister,setShowRegister] = useState(false)

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Leads</title>
          <meta name="description" content="Manage and track potential gym members with the Fitmi Studios dashboard. Add and organize lead contact details, and seamlessly convert leads to members for streamlined gym management." />
          <meta name="og:title" property="og:title" content="Fitmi Studios Leads | Lead Management and Conversion Tracking"/>
          <meta property="og:url" content="https://www.fitmistudios.com/leads" />
          <meta name="og:description" property="og:description" content="Efficiently manage and organize lead information with Fitmi Studios. Save contact details, track leads, and convert them to members for an organized gym management experience."/>
          <meta name="keywords" content="fitmi studios, lead management, gym leads, convert leads to members, manage leads, organize leads, gym member tracking, lead conversion" />
          <link rel="canonical" href="https://www.fitmistudios.com/leads" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Fitmi Studios Leads",
              "description": "Track and manage leads for your gym with the Fitmi Studios dashboard. Save contact details, organize leads, and convert them to members for streamlined operations.",
              "url": "https://www.fitmistudios.com/leads"
            }`}
          </script>
      </Helmet>
      <div className='pageSplit'>
        <div>
          <SideMenu/>
        </div>
        <div className='mainContainer'>
        <div className='nameBox'>
          <span className='pageTitle'>Leads</span>
        </div>
          <div className='btnVisible'>
            <button className='RegisterBtn' onClick={()=>setShowRegister(true)}>Add New Leads</button>
          </div>
          <div className='mobileReg' onClick={()=>setShowRegister(true)}>+</div>
          {
            showRegister?
            <RegistrationPopUp from="checkIn" hide={setShowRegister}/>:''
          }
          <UserTable from="checkIn"  reRenderData={showRegister} />
        </div>
      </div>
    </div>
  )
}

export default Leads