import React, { useEffect, useState } from 'react'
import './SideMenu.scss'
import { useNavigate } from 'react-router-dom'
import Dash from '../../Assets/icons/dashboard.png'
import Trainers from '../../Assets/icons/trainer.png'
import Member from '../../Assets/icons/member.png'
import Payment from '../../Assets/icons/payment.png'
import Referrals from '../../Assets/icons/refer.png'
import Settings from '../../Assets/icons/support.png'
import Logo from '../../Assets/Logo/logo.png'
import { signOut } from 'firebase/auth'
import { auth, database } from '../../FirebaseConfig/config'
import CommonAlertTwo from '../CommonAlertTwo/CommonAlertTwo'
import { collection, doc, getDocs } from 'firebase/firestore'
import Support from '../Support/Support'

function SideMenu({from}) {
    const [active,setActive] = useState('dashboard')
    const [showAlert,setShowAlert] = useState(false)
    const [showSupport,setShowSupport] = useState(false)
    const [details,setDetails] = useState('')
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const navigate = useNavigate()

    const handleActive =(menu)=>{
        setActive(menu)
        navigate(`/${menu}`)   
    }
    useEffect(()=>{
        const urlPath = window.location.pathname
        const splitUrl = urlPath.split('/')
        setActive(splitUrl[1])
    },[])

    const handleLogout =()=>{
        signOut(auth).then(()=>{
          navigate("/")
        }).catch((err)=>console.log("error",err))
    }

    useEffect(() => {
        const handleResize = () => {
        setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(()=>{
        const getCompanyName = async()=>{
          const uid = localStorage.getItem("uid");
          if (uid) {
            const companyCollection = doc(database,localStorage.getItem("uid"),'common')
            const valueDb = collection(companyCollection,'companyDetails')
            const data = await getDocs(valueDb)
            const companyDetails = data.docs.map((doc)=>({...doc.data(),id:doc.id}))
            setDetails(companyDetails)
          }
        }
        getCompanyName()
      },[])

  return (
    <div>
        <div style={{ minHeight: viewportHeight }} className='sideMenu'>
            <div>
                <div className='logo'>
                    <span onClick={()=>setShowAlert(true)} style={{color: 'var(--active-title)'}}><img src={Logo} className='logoHeight' alt='fitmi studios logo' /></span>
                </div>
                {
                    from=="admin"?
                    <div className='menuList'>
                        <div onClick={()=>handleActive('adminboard')}>
                            <div className={active === 'adminboard'?'activeDiv':'nonActive'}>
                                <img src={Dash} className='sideImageHeight' alt='dashboard icon' /><br/><span className='menuTitle'>AdminBoard</span>
                            </div>
                        </div>
                        <div onClick={()=>handleActive('customize')}>
                            <div className={active === 'customize'?'activeDiv':'nonActive'}>
                                <img src={Dash} className='sideImageHeight' alt='dashboard icon' /><br/><span className='menuTitle'>Customize</span>
                            </div>
                        </div>
                        <div onClick={()=>handleActive('feedback')}>
                            <div>
                            <div className={active === 'feedback'?'activeDiv':'nonActive'}>
                                <img src={Settings} height='35px' alt='settings icon' /><br/><span className='menuTitle'>Feedback</span>
                            </div>
                            </div>
                        </div>
                    </div>:
                    <div className='menuList'>
                        <div  onClick={()=>handleActive('dashboard')}>
                            <div className={active === 'dashboard'?'activeDiv':'nonActive'}>
                                <img src={Dash} className='sideImageHeight' alt='dashboard icon' /><br/><span className='menuTitle'>DashBoard</span>
                            </div>
                        </div>
                        <div className={active === 'members'?'activeDiv':'nonActive'} onClick={()=>handleActive('members')}>
                            <div>
                                <img src={Member} className='sideImageHeight' alt='members icons' /><br/><span className='menuTitle'>Members</span>
                            </div>
                        </div>
                        <div className={active === 'trainers'?'activeDiv':'nonActive'} onClick={()=>handleActive('trainers')}>
                            <div>
                                <img src={Trainers} className='sideTrainnerImageHeight' alt='trainer icon' /><br/><span className='menuTitle'>Trainers</span>
                            </div>
                        </div>
                        <div className={active === 'payments'?'activeDiv':'nonActive'} onClick={()=>handleActive('payments')}>
                            <div>
                                <img src={Payment} className='sideImageHeight' alt='payment icon' /><br/><span className='menuTitle'>Payments</span>
                            </div>
                        </div>
                        <div className={active === 'leads'?'activeDiv':'nonActive'} onClick={()=>handleActive('leads')}>
                            <div>
                                <img src={Referrals} height='35px' alt='leads icon' /><br/><span className='menuTitle'>Leads</span>
                            </div>
                        </div>
                        <div onClick={()=>setShowSupport(true)}>
                            <div>
                                <img src={Settings} height='35px' alt='settings icon' /><br/><span className='menuTitle'>Support</span>
                            </div>
                        </div>
                        {/* <div className={active === 'settings'?'activeDiv':'nonActive'} onClick={()=>handleActive('settings')}>
                            <div>
                                <img src={Support} height='35px' /><br/><span className='menuTitle'>Settings</span>
                            </div>
                        </div> */}
                        {/* <div className='logout' onClick={()=>setShowAlert(true)}>
                            <div className='subLogout'>
                                <img src={Logout} height='40px' /><br/><span className='menuTitle'>Logout</span>
                            </div>
                        </div> */}
                    </div>
                }
            </div>
        </div>
        {
           showAlert?<CommonAlertTwo hide={setShowAlert} msg='Are you sure you want to logout?' />:'' 
        }
        {
           showSupport?<Support cDetails={details[0]} hide={setShowSupport}/>:'' 
        }
    </div>
  )
}

export default SideMenu