import React, { useState } from 'react'
import './Members.scss'
import SideMenu from '../Component/SideMenu/SideMenu'
import RegistrationPopUp from '../Component/RegisterForm/RegisterForm'
import UserTable from '../Component/UserTable/UserTable'
import { Helmet } from 'react-helmet'


function Members() {
  const [showRegister,setShowRegister] = useState(false)

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Members</title>
          <meta name="description" content="Manage gym members with ease using the Fitmi Studios dashboard. Add, edit, and delete members, manage fees, track membership status, and export member details to PDF. Filter by join date, payment status, and more to streamline your gym operations." />
          <meta name="og:title" property="og:title" content="Fitmi Studios Members | Gym Membership Management"/>
          <meta property="og:url" content="https://www.fitmistudios.com/members" />
          <meta name="og:description" property="og:description" content="Effortlessly manage your gym memberships with Fitmi Studios. Add, edit, and delete members, manage fees, update statuses, and export details to PDF. Filter members by joining date, payment status, and more."/>
          <meta name="keywords" content="fitmi studios, gym membership management, gym members, manage fees, export members, filter members, membership status, gym management" />
          <link rel="canonical" href="https://www.fitmistudios.com/members" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Fitmi Studios Members",
              "description": "Easily manage gym members, fees, statuses, and more with the Fitmi Studios dashboard. Export member details to PDF and filter by criteria like joining date and payment status for efficient gym membership tracking.",
              "url": "https://www.fitmistudios.com/members"
            }`}
          </script>
      </Helmet>
      <div className='pageSplit'>
        <div>
          <SideMenu/>
        </div>
        <div className='mainContainer'>
          <div className='nameBox'>
            <span className='pageTitle'>Members</span>
          </div>
          <div className='btnVisible'>
            <button className='RegisterBtn' onClick={()=>setShowRegister(true)}>Add New Member</button>
          </div>
          <div className='mobileReg' onClick={()=>setShowRegister(true)}>+</div>
          {
            showRegister?
            <RegistrationPopUp from="member" hide={setShowRegister}/>:''
          }
          <UserTable from="member"  reRenderData={showRegister} />
        </div>
      </div>
    </div>
  )
}

export default Members