import React from 'react'
import './CommonAlert.scss'

function CommonAlert(props) {
    if(props.msg == "Please check your email."){
        props.closeReset(false)
    }

  return (
    <div>
        <div className='commonBg'>
            <div className='commpnAlert'>
                <div>
                    <div className='commonContent'>
                        <span>{props.msg}</span>
                    </div>
                    <div  className='commonContentBtn'>
                        <button onClick={()=>props.hide(false)}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CommonAlert