import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({from,isAuthenticated,children})=>{
    return isAuthenticated ? children: <Navigate to={from=="admin"?"/fitadminmi":"/login"}/>
}

const CommonDetails = React.createContext()

export { PrivateRoute };
export default CommonDetails;