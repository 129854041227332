import { initializeApp } from "firebase/app";
import { getAuth,GoogleAuthProvider } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBnF_w-rXETTk89LOgPk01GRpEwkUBZUjg",
    authDomain: "fitme-12990.firebaseapp.com",
    databaseURL: "https://fitme-12990.firebaseio.com",
    projectId: "fitme-12990",
    storageBucket: "fitme-12990.appspot.com",
    messagingSenderId: "778784663084",
    appId: "1:778784663084:web:8ccb70adb650e2d48c5389",
    measurementId: "G-FT1EF0C5Q9"
  };

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const imageDb = getStorage(app)
const database = getFirestore(app)
const provider = new GoogleAuthProvider()
export { auth,provider,imageDb,database }