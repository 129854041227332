import React, { useEffect, useState } from 'react'
import './Dashboard.scss'
import { auth, database } from '../FirebaseConfig/config'
import { addDoc, collection, doc, getDocs, orderBy, query } from 'firebase/firestore'
import Members from '../Assets/icons/member.png'
import Trainers from '../Assets/icons/trainer.png'
import Revenue from '../Assets/icons/revenue.png'
import Expence from '../Assets/icons/monthly.png'
import Pending from '../Assets/icons/pending.png'
import email_ic from '../Assets/email.png'
import whatsapp_ic from '../Assets/whatsapp.png'
import SideMenu from '../Component/SideMenu/SideMenu'
import Equipment from '../Component/Equipment/Equipment'
import { Helmet } from 'react-helmet'

function Dashboard() {
  const [users,setUsers] = useState([])
  const [currentMonthList,setCurrentMonthList] = useState([])
  const [dueList,setDueList] = useState([])
  const [currentMonthEarnList,setCurrentMonthEarnList] = useState([])
  const [filterList,setFilterList] = useState({activetrainees:[]})
  const [showEquipment,setShowEquipment] = useState(false)
  const [companyName,setCompanyName] = useState({name:'',id:''})
  const [showHideCompanyPopup,setShowHideCompanyPopup] = useState(false)
  const [companyCreated,setCompanyCreated] = useState('')
  const [message,setMessage] = useState({
    mMsg:'',
    tMsg:''
  })
  const [ePModal,setEPModal] = useState('')
  const [isOffline,setIsOffline] = useState(false)
  const [allLength,setAllLength] = useState({
    totalMember:0 ,
    completedMember:0 ,
    activeMember:0,
    totalTrainer:0,
    activeTrainer:0,
    inActiveTrainer:0,
    totalRevinue:0,
    monthlyRevinue:0,
    pendingAmount:0
  })

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth()+1
  const currentYr = currentDate.getFullYear()
  useEffect(()=>{
    if (navigator.onLine) {
      setIsOffline(false)
    } else {
      setIsOffline(true)
    }
  })

    const getUsers = async()=>{
          const mTCol = doc(database,localStorage.getItem("uid"),'mT')
          const valueDb = collection(mTCol,'list')
          const orderByData = query(valueDb, orderBy('jData'));
          const data = await getDocs(orderByData)
          let response = ''
          response = data.docs.map((doc)=>({...doc.data(),id:doc.id}))
          setUsers(response)
    }

    
    useEffect(()=>{
      auth.onAuthStateChanged((existingUser)=>{
        try {
          setCompanyCreated(existingUser?.metadata?.creationTime)
          localStorage.setItem("uid",existingUser.uid?existingUser.uid:existingUser.providerData[0].uid)
        } catch (error) {
          console.log(error)
        }
      })
      getUsers()
    },[])

    useEffect(()=>{
      const aT = users.filter((doc) => doc.type === "T" && doc.isActiveTrainer === "Y")
      const iT = users.filter((doc) => doc.type === "T" && doc.isActiveTrainer === "N")
      const tT = users.filter((doc) => doc.type === "T")
      const aM = users.filter((doc) => doc.type === "M" && doc.category === "N")
      const cM = users.filter((doc) => doc.type === "M" && doc.category === "C")
      const tM = users.filter((doc) => doc.type === "M")

      // totalT income
      let totalTAmount = users.reduce((total, item) => {
        if (item.isEmi === true && item.type === 'M') {
          return total + (parseFloat(item.pAmount) || 0);
      } else {
          return total + (parseFloat(item.tAmount) || 0);
      }
    }, 0);

      setAllLength(preVal =>({...preVal,activeTrainer:aT.length}))
      setAllLength(preVal =>({...preVal,inActiveTrainer:iT.length}))
      setAllLength(preVal =>({...preVal,totalTrainer:tT.length}))
      setAllLength(preVal =>({...preVal,activeMember:aM.length}))
      setAllLength(preVal =>({...preVal,completedMember:cM.length}))
      setAllLength(preVal =>({...preVal,totalMember:tM.length}))
      setAllLength(preVal =>({...preVal,totalRevinue:totalTAmount}))
      
      // find pending list
      users.forEach(val=>{
        if(val.type === "M" && val.category == "N"){
          let pending = JSON.parse(val.pAmount)
          let total = JSON.parse(val.tAmount)
          if(pending < total){
              let pendingList = {name:val.name,dueBalance:val.tAmount-val.pAmount,totalAmount: val.tAmount,paidAmount:val.pAmount}
              setDueList(data=>[...data,pendingList]) 
          }
        }
      })

      users.forEach(val=>{
        if(val.type === "M"){
          let splitDate = val.jData.split('-')
          if(splitDate[0] == currentYr){
            if(splitDate[1] == currentMonth){
              setCurrentMonthList(data=>[...data,{name:val.name,phone:val.phone,email:val.email}])
            }
          }
        }
      })
      
      setFilterList((existing)=>({...existing,activetrainees:aT})) // active trainers list

      tM.forEach((val)=>{
        // get Total earning list for this month
        val.payments.forEach((data)=>{
          const paidDate = JSON.parse(data.paidDate).split('-')
          const paidmonth = paidDate[1]
          const paidYr = paidDate[0]
          if(paidmonth == currentMonth){
              if(paidYr == currentYr){
              setCurrentMonthEarnList(pay=>([...pay,data]))
            }
          }
        })
      })
    },[users])

    useEffect(()=>{
      // pending payment calculation
      let totalDue = dueList.reduce((total, item) => {
        return total + item.dueBalance
      },0)
      setAllLength(preVal =>({...preVal,pendingAmount:totalDue}))
    },[dueList])

    useEffect(()=>{
      // pending payment calculation
      let totalDue = currentMonthEarnList.reduce((total, item) => {
        return Number(total) + Number(item.amount)
      },0)
      setAllLength(preVal =>({...preVal,monthlyRevinue:totalDue}))
    },[currentMonthEarnList])

    useEffect(()=>{
      const getCompanyDetails = async()=>{
        const commonCollection = doc(database,localStorage.getItem("uid"),'common')
        const messageData = await getDocs(collection(commonCollection,'companyDetails'))
        const messageDetails = messageData.docs.map((doc)=>({...doc.data(),id:doc.id}))
        setMessage({
          ...message,
          mMsg:messageDetails[0]?.message,
          tMsg:messageDetails[0]?.tMessage
        })
      }
      getCompanyDetails()
    },[])
    
    const handleShowModal =(type)=>{
      setEPModal(type) // EP -> equipment and Profile
      setShowEquipment(true)
    }

    const getCompanyName = async()=>{
      const companyCollection = doc(database,localStorage.getItem("uid"),'common')
      const valueDb = collection(companyCollection,'companyDetails')
      const data = await getDocs(valueDb)
      const companyDetails = data.docs.map((doc)=>({...doc.data(),id:doc.id}))
      localStorage.setItem('companyName',companyDetails[0]?.cName)
      setCompanyName({name:companyDetails[0]?.cName ? companyDetails[0]?.cName: '',id:companyDetails[0]?.id ? companyDetails[0]?.id : ''})
      if(companyDetails[0]?.cName){
        setShowHideCompanyPopup(false)
      }else{
        setShowHideCompanyPopup(true)
      }
    }
    useEffect(()=>{
      getCompanyName()
    },[showEquipment])

    const handleGetCompanyName = async(e) =>{
      e.preventDefault()
          const companyCollection = doc(database,localStorage.getItem("uid"),'common')
          const valueDb = collection(companyCollection,'companyDetails')
          const initialDate = new Date(companyCreated);
          const nextPay = initialDate.setDate(initialDate.getDate() + 60);
              try{
                setShowHideCompanyPopup(false)
                await addDoc(valueDb,{
                    cName:companyName.name,
                    message:`Hi this from ${companyName.name}, Today, the gym is closed.`,
                    tMessage:`Hi this from ${companyName.name},`,
                    nextPaymentDate: nextPay,
                    created:new Date(),
                    updated:new Date(),
                  })
              }catch(e){
                  console.log(e,"error")
              }

              // add plan details
              let planDetails = {
                cName: companyName.name?companyName.name:localStorage.getItem('companyName'),
                cUID: localStorage.getItem("uid"),
                plan: 'T',
                mailSentDate: '',
                created:new Date()
              }
              const planDet = doc(database,'planDetails',localStorage.getItem("uid"))
              const planRef = collection(planDet,'about')
              addDoc(planRef,planDetails)
    }

    return (
    <div style={{overflowY:'hidden',height:"100vh"}}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Dashboard</title>
            <meta name="description" content="Track your gym's performance with the Fitmi Studios dashboard. View metrics like total members, trainers, monthly joiners, revenue, and pending payments to streamline gym management and boost financial growth." />
            <meta name="og:title" property="og:title" content='Fitmi Studios Dashboard | Gym Management System Overview'/>
            <meta property="og:url" content="https://www.fitmistudios.com/dashboard" />
            <meta name="og:description" property="og:description" content="Manage your gym with Fitmi Studios' dashboard. Access member and trainer statistics, revenue reports, and pending payments for efficient gym management and growth."/>
            <meta name="keywords" content="fitmi studios, gym management system, gym dashboard, total members, monthly revenue, pending payments, gym growth, fitness studio" />
            <link rel="canonical" href="https://www.fitmistudios.com/dashboard" />
            <meta name="robots" content="index, follow" />
            <script type="application/ld+json">
              {`{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Fitmi Studios Dashboard",
                "description": "Track key gym metrics like member count, revenue, and pending payments. Manage gym operations efficiently with the Fitmi Studios dashboard.",
                "url": "https://www.fitmistudios.com/dashboard"
              }`}
            </script>
        </Helmet>
        <div className='pageSplit'>
            <div style={{overflowY:'hidden'}}>
              <SideMenu/>
            </div>
            <div className='dashboardBg'>
              <div className='dashboardTitle'>
                <div className='companyNameContainer'>
                  <div className='welcome'>{companyName.name}</div>
                  {/* <div className='welcome' onClick={()=>setShowHideCompanyPopup(!showHideCompanyPopup)}>{companyName.name}</div> */}
                </div>
              </div>
              <div className='mainScroll'>
              <div className='splitDash'>
              <div className='mainCard'>
                <div className='summaryContainer'>
                  {/* <div className='subTitle'>Summary</div> */}
                  <div className='cardMain'>
                    <div className='cardList cardOne'>
                      <div>
                        <img src={Members} height='30px' alt='members count' /><br/>
                        <div className='titleContainer'>
                          <span className='cardName'>Active Members</span><br/>
                        </div>
                        <span className='cardValue'>{allLength.activeMember}</span>
                        <div className='circleDesignTop'></div>
                        <div className='circleDesignBtm'></div>
                      </div>
                    </div>
                    <div className='cardList cardTwo'>
                      <div>
                          <img src={Trainers} height='35px' alt='trainers count' /><br/>
                          <div className='titleContainer'>
                            <span className='cardName'>Total Trainers</span><br/>
                          </div>
                          <span className='cardValue'>{allLength.totalTrainer}</span>
                          <div className='circleDesignTop'></div>
                          <div className='circleDesignBtm'></div>
                      </div>
                    </div>
                    <div className='cardList cardThree'>
                      <div>
                        <img src={Revenue} height='30px' alt='total revenue' /><br/>
                        <div className='titleContainer'>
                          <span className='cardName'>Total Revenue</span><br/>
                        </div>
                        <span className='cardValue' >{allLength.totalRevinue}</span>
                        <div className='circleDesignTop'></div>
                        <div className='circleDesignBtm'></div>
                      </div>
                    </div>
                    <div className='cardList cardFour'>
                      <div>
                        <img src={Expence} height='30px' alt='total expences' /><br/>
                        <div className='titleContainer'>
                            <span className='cardName'>Monthly Revenue</span><br/>
                        </div>
                        <span className='cardValue'>{allLength.monthlyRevinue}</span>
                        <div className='circleDesignTop'></div>
                        <div className='circleDesignBtm'></div>
                      </div>
                    </div>
                    <div className='cardList cardFive'>
                      <div>
                        <img src={Pending} height='30px' alt='panding payments' /><br/>
                        <div className='titleContainer'>
                            <span className='cardName'>Pending Payment</span><br/>
                        </div>
                        <span className='cardValue' >{allLength.pendingAmount}</span>
                        <div className='circleDesignTop'></div>
                        <div className='circleDesignBtm'></div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='innerSubContainer'>
                  <div className='subContainer'>
                      <div className='titleContainer'><span className='subTitle'>Joined this Month - {currentMonthList?currentMonthList.length:0}</span></div>
                    <div className='currentMonthStudent'>
                      <div className='innerPadding'>
                        <div className='availableTrainees'>
                          <div className='currentMonStudent'>
                            {
                              currentMonthList.map((val,i)=><div key={i} className='trainersList'>
                                <span className='nameElipsis' title={val.name} style={{padding:"5px 0"}}>{val.name}</span>
                                <span style={{padding:"5px 0"}}>{val.phone}</span>
                                <div style={{padding:"7px 0 0 0"}} className='chatIcon'>
                                  <a className='mobileMail' href={`https://mail.google.com/mail/?view=cm&to=${encodeURIComponent(val.email)}&su=${encodeURIComponent(companyName.name)}&body=${encodeURIComponent(message.mMsg)}`} target="_blank" rel="noopener noreferrer">
                                    <img height='20px' src={email_ic} alt='send mail' />
                                  </a>
                                    <a href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(val.phone)}&text=${encodeURIComponent(message.mMsg)}`} target="_blank" rel="noopener noreferrer">
                                      <img height='20px'src={whatsapp_ic} alt='send whatsapp message' />
                                    </a>
                                </div>
                              </div>)
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='needToDecide'>
                    <div className='availableTrainees'>
                    <div className='subContainer'>
                    <div className='titleContainer'><span className='subTitle'>Available Trainers - {filterList.activetrainees?filterList.activetrainees.length:0}</span></div>
                      <div className='currentMonthStudent'>
                        <div className='innerPadding'>
                          <div>
                            <div className='currentMonStudent'>
                              {
                                filterList.activetrainees.map((val,i)=><div key={i} className='trainersList'>
                                  <span className='nameElipsis' title={val.name} style={{padding:"5px 0"}}>{val.name}</span>
                                  <span style={{padding:"5px 0"}}>{val.phone}</span>
                                  <div style={{padding:"7px 0 0 0"}} className='chatIcon'>
                                    <a className='mobileMail' href={`https://mail.google.com/mail/?view=cm&to=${encodeURIComponent(val.email)}&su=${encodeURIComponent(message.tMsg)}&body=${encodeURIComponent(message.cMsg)}`} target="_blank" rel="noopener noreferrer">
                                      <img height='20px' src={email_ic} alt='send mail' />
                                    </a>
                                    <a href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(val.phone)}&text=${encodeURIComponent(message.tMsg)}`} target="_blank" rel="noopener noreferrer">
                                      <img height='20px'src={whatsapp_ic} alt='send whatsapp message' />
                                    </a>
                                  </div>
                                </div>)
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='rightContainer'>
                <div className='dueContainer'>
                  <div style={{padding:"0px 10px"}}>
                    <div className='due'>
                        <span className='subTitle'>Due balance list</span>
                    </div>
                    <div className='due'>
                      {
                        dueList.map((user,i)=><div key={i} className='dueList'>
                          <span className='nameElipsis'>{user.name}</span>
                          <span>
                            <div className='dueAmtRow'>
                              <div className='dueRow'><div className='dotted'><span style={{fontWeight:"900",fontSize:'12px'}}>{user.totalAmount}</span></div>/<div className='dotted'><span style={{fontWeight:"500",fontSize:'12px' }}>{user.paidAmount} </span></div></div>
                              <div><span style={{fontWeight:"800",color:"var(--active-title)",fontSize:'13px',textAlign:'center'}}> = {user.dueBalance}</span></div>
                            </div>
                          </span>
                        </div>)
                      }
                    </div>
                  </div>
                </div>
                <div className='clickAction'>
                      <div className='foodList' style={{marginRight:"10px"}} onClick={()=>handleShowModal('equipment')}>
                            Add/Edit<br/> Equipments
                      </div>
                      <div className='foodList' onClick={()=>handleShowModal('profile')}>
                            Profile
                      </div>
                    </div>
              </div>
              </div>
              </div>
            </div>
        </div>
        {
          showEquipment? 
          <Equipment show={setShowEquipment} from={ePModal} />
          :''
        }
        {
          showHideCompanyPopup && !isOffline ?  
        <div>
          <div className='companyContainer'>
            <div className='companyPopup'>
              <form onSubmit={handleGetCompanyName}>
                  <div className='conpanyContainer'>
                    <span className='companyNameLabel'>Your Company Name</span>
                  </div>
                  <input name='cName' value={companyName.name} onChange={(e)=>setCompanyName({ ...companyName,name:e.target.value})} /><br/>
                  <button>Save</button>
              </form>
            </div>
          </div>
        </div>:''
        }
    </div>
  )
}

export default Dashboard