import React, { useContext, useState } from 'react'
import './Settings.scss'
import SideMenu from '../Component/SideMenu/SideMenu'
import Support from '../Component/Support/Support'
import CommonDetails from '../Utils'

function Settings() {
    const [showSupport,setShowSupport] = useState(false)
    const commonValue = useContext(CommonDetails)
    
  return (
    <div>
      <div className='pageSplit'>
        <div>
          <SideMenu/>
        </div>
        <div className='mainContainer'>
            <button className='supportBtn' onClick={()=>setShowSupport(true)}>Suppoprt</button>
          {
            showSupport?
            <Support cDetails={commonValue[0]} from="support" hide={setShowSupport}/>:''
          }
        </div>
      </div>
    </div>
  )
}

export default Settings