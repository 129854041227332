import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './Dashboard/Dashboard';
import Login from './Login/Login';
import Payments from './Payments/Payments';
import Members from './Members/Members';
import Trainers from './Trainers/Trainers';
import FreeTrial from './Component/FreeTrial/FreeTrial';
import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs } from 'firebase/firestore';
import { auth, database } from './FirebaseConfig/config';
import CommonAlert from './Component/CommonAlert/CommonAlert';
import Settings from './Settings/Settings';
import CommonDetails, { PrivateRoute } from './Utils';
import GetStarted from './GetStarted/GetStarted';
import Leads from './Leads/Leads';
import AdminLogin from './Admin/AdminLogin';
import Admin from './Admin/Admin';
import Customize from './Admin/CustomizeAndFeedback/CustomizeAndFeedback';

function App() {
const[show,setShow] = useState(false)
const[internet,setInterNet] = useState(false)
const[created,setCreated] = useState('')
const[nextPayment,setNestPayment] = useState('')
const[UID,setUID] = useState('')
const[details,setDetails] = useState('')
const [isLoggedIn,setIsLoggedIn] = useState(false)
const [isLoggedInAdmin,setIsLoggedInAdmin] = useState(false)

useEffect(()=>{
  const getCompanyName = async()=>{
    const uid = localStorage.getItem("uid");
    if (uid) {
      const companyCollection = doc(database,localStorage.getItem("uid"),'common')
      const valueDb = collection(companyCollection,'companyDetails')
      const data = await getDocs(valueDb)
      const companyDetails = data.docs.map((doc)=>({...doc.data(),id:doc.id}))
      setNestPayment(companyDetails[0]?.nextPaymentDate)
      setDetails(companyDetails)
    }
  }
  getCompanyName()
},[])


useEffect(()=>{
  const checkInternetConnection=()=> {
    if (navigator.onLine) {
        console.log('Internet connection is active.');
    } else {
      setInterNet(true)
      console.log('No internet connection.');
    }
  }
  checkInternetConnection();
})

useEffect(()=>{
  auth.onAuthStateChanged((existingUser)=>{
    try {
      setCreated(existingUser?.metadata?.creationTime)
      setUID(existingUser?.uid)
      if(existingUser?.uid){
        setIsLoggedIn(true)
      }
      localStorage.setItem("uid",existingUser?.uid?existingUser?.uid:existingUser?.providerData[0]?.uid)
    } catch (error) {
      console.log(error)
      setIsLoggedIn(false)
    }
  })
},[])

useEffect(()=>{
  const initialDate = new Date();
  const currentDate = initialDate.setDate(initialDate.getDate());
  if(currentDate >= nextPayment ){
    // setShow(true)
  }
},[created])
  
  return (
    <BrowserRouter>
    {
      show?
      <FreeTrial uid={UID}/> :''
    }
      <CommonDetails.Provider value={details}>
        <Routes>
          <Route path='/' element={<GetStarted/>} /> 
          <Route path='/login' element={<Login/>} /> 
          <Route path='/dashboard' element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Dashboard/>
            </PrivateRoute>
            } /> 
          <Route path='/members' element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Members/>
            </PrivateRoute>
          } /> 
          <Route path='/trainers' element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Trainers/>
            </PrivateRoute>
          } /> 
          <Route path='/payments' element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Payments/>
            </PrivateRoute>
          } /> 
          <Route path='/leads' element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Leads/>
            </PrivateRoute>
          } /> 
          <Route path='/settings' element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Settings/>
            </PrivateRoute>
          } />
          <Route path='/fitadminmi' element={<AdminLogin isLoggedIn={setIsLoggedInAdmin}/>} />
          <Route path='/adminboard' element={
            <PrivateRoute from="admin" isAuthenticated={isLoggedInAdmin}>
              <Admin/>
            </PrivateRoute>
          } />
          <Route path='/customize' element={
            <PrivateRoute from="admin" isAuthenticated={isLoggedInAdmin}>
              <Customize from='customize'/>
            </PrivateRoute>
          } />
          <Route path='/feedback' element={
            <PrivateRoute from="admin" isAuthenticated={isLoggedInAdmin}>
              <Customize from='feedback' />
            </PrivateRoute>
          } />
        </Routes>
      </CommonDetails.Provider>
      {
        internet?<CommonAlert hide={setInterNet} msg="No Internet" />:""
      }
    </BrowserRouter>
  );
}

export default App;
