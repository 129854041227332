import React, { useEffect } from 'react'
import './Admin.scss'
import SideMenu from '../Component/SideMenu/SideMenu'

function Admin() {

  useEffect(() => {
    const url = window.location.hostname.includes('fitmistudios')
    ? 'https://www.fitmistudios.com'
    : 'http://localhost:5000';
    
    fetch(`${url}/api/users`)
      .then((response) => response.json())
      .then((data) => console.log(data,"datatatta"))
      .catch((error) => console.error('Error fetching user emails:', error));
  }, []);

  return (
    <div>
        <div className='pageSplit'>
            <div>
            <SideMenu from="admin"/>
            </div>
            <div className='mainContainer'>
                Admin
            </div>
        </div>
    </div>
  )
}

export default Admin