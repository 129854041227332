import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../FirebaseConfig/config";
import CommonAlert from "./CommonAlert";

function ResetPassword(props){
    const [show,setShow] = useState(false)
    const [msg,setMsg] = useState('')

    const handleSubmit = async(e)=>{
        e.preventDefault()
            const emalVal = e.target.email.value;
            sendPasswordResetEmail(auth,emalVal).then(data=>{
                setMsg("Please check your gmail.")
                setShow(true)
            }).catch(err=>{
                setShow(true)
                setMsg(err.code)
            })
    }

    return(
        <div>
            <div className='commonBg'>
                <div className='commpnAlert'>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className='commonContent'>
                            <div>
                                <span>Reset Password</span><br/>
                                <input name="email" placeholder='Email' /><br/>
                            </div>
                            </div>
                            <div  className='commonContentBtn'>
                                <button>Reset</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                show?
                <CommonAlert closeReset={props.hide} msg={msg == "auth/user-not-found"?"Please enter a valid email address.":"Please check your email."} hide={setShow} />:''
            }
        </div>
    )
}
export default ResetPassword