import React, { useEffect, useState } from 'react'
import './FreeTrial.scss'
import email_ic from '../../Assets/icons/gmail.png'
import whatsapp_ic from '../../Assets/whatsapp.png'
import { collection, doc, getDocs } from 'firebase/firestore'
import { database } from '../../FirebaseConfig/config'

function FreeTrial(props) {
    const [details,setDetails] = useState({
        cNameId:'',
        cName:'',
        cMsgId:'',
        cMsg:'',
      })

    useEffect(()=>{
        const getCompanyDetails = async()=>{
          const commonCollection = doc(database,localStorage.getItem("uid"),'common')
          const companyData = await getDocs(collection(commonCollection,'companyDetails'))
          const companyDetails = companyData.docs.map((doc)=>({...doc.data(),id:doc.id}))
          const messageData = await getDocs(collection(commonCollection,'message'))
          const messageDetails = messageData.docs.map((doc)=>({...doc.data(),id:doc.id}))
          setDetails({
            ...details,
            cName:companyDetails[0]?.cName,
            cNameId:companyDetails[0]?.id,
            cMsg:messageDetails[0]?.cMsg,
            cMsgId:messageDetails[0]?.id
          })
        }
        getCompanyDetails()
      },[])

    const handlePayMsg = () =>{

    }

    let email = 'akajith919@gmail.com'
    let phone = '7708547764'
    let msg = `Hi we are ${details.cName},\ncompany id ${props.uid}\nWe are currently seeking to upgrade our existing plan..`

  return (
    <div>
        <div className='freeTrial'>
            <div className='freeCard'>
                <div>
                    <span className='trialLabel'>Your trial period has expired. Please contact the administrator.</span>
                    <div className='btnContainer'>
                        
                        <a className='aTag' href={`https://mail.google.com/mail/?view=cm&to=${encodeURIComponent(email)}&su=${encodeURIComponent(details.cName)}&body=${encodeURIComponent(msg)}`} target="_blank" rel="noopener noreferrer">
                        <div className='trialBtn' onClick={handlePayMsg}> <img height='15px' src={email_ic} alt='send mail navigatation' /> <span>Continue</span>
                        </div>
                        </a>
                        <a className='aTag' href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(phone)}&text=${encodeURIComponent(msg)}`} target="_blank" rel="noopener noreferrer">
                        <div className='trialBtn' onClick={handlePayMsg}>
                            <img height='24px'src={whatsapp_ic} alt='whatsapp navigation' />  <span>Continue</span>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FreeTrial