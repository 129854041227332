import React, { useEffect, useState } from 'react'
import './UserTable.scss'
import { collection, deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore'
import { database } from '../../FirebaseConfig/config'
import UpdatePayAndMove from '../UpdatePayMove/UpdatePayMove'
import RegistrationPopUp from '../RegisterForm/RegisterForm'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Loading from '../Loading/Loading'

function UserTable(props) {
  const [users,setUsers] = useState([])
  const [userCopy,setUserCopy] = useState([])
  const [result,setResult] = useState(false)
  const [deleteChecked,setDeleteChecked] = useState([])
  const [exportChecked,setExportChecked] = useState([])
  const [showPay,setShowCustomPopup] = useState(false)
  const [showRegister,setShowRegister] = useState(false)
  const [showType,setShowType] = useState('')
  const [chooseUser,setChooseUser] = useState('')
  const [edit,setEdit] = useState()
  const [deleteUser,setDeleteUser] = useState(false)
  const [activefilter,setActivefilter] = useState('all')
  const [selectedOption,setSelectedOption] = useState('all')
  const [paymentList,setPaymantList] = useState([])
  const [paymentListCopy,setPaymantListCopy] = useState([])
  const [showDate,setShowDate] = useState(false)
  const [searchData,setSearchData] = useState('')
 
  useEffect(()=>{
    getUsers()
  },[showPay])

  const getUsers = async()=>{
    try {
        setResult(false)
        const mTCol = doc(database,localStorage.getItem("uid"),'mT')
        const valueDb = collection(mTCol,'list')
        const orderByData = query(valueDb, orderBy('jData', 'desc'));
        const data = await getDocs(orderByData)
        const userList = data.docs.filter((doc) => props.from == 'trainer'? doc.data().type === "T" :( props.from == 'checkIn'?doc.data().type === "C": doc.data().type === "M") ).map((doc)=>({...doc.data(),id:doc.id}))
        setUsers(userList)
        setUserCopy(data.docs.filter((doc) => props.from == 'trainer'? doc.data().type === "T" : ( props.from == 'checkIn'?doc.data().type === "C":doc.data().type === "M")).map((doc)=>({...doc.data(),id:doc.id})))

        if(props.from == 'payment'){
          userList.forEach((val,i)=>{
            if(val.tAmount>0){
              val.payments.forEach(pay=>{
                if(pay?.amount !==''){
                  setPaymantList(data=>[...data,{name:val.name,email:val.email,phone:val.phone,amount:pay.amount,paidDate:JSON.parse(pay.paidDate),id:val.id}])
                  setPaymantListCopy(data=>[...data,{name:val.name,email:val.email,phone:val.phone,amount:pay.amount,paidDate:JSON.parse(pay.paidDate),id:val.id}])
                }
              })
            }
          })
        }
      } catch (error) {
          setResult(true)
          console.log(error)
          
      }
  }

  const handleSearch=(e)=>{
    e.preventDefault();
    setSearchData(e.target.value)
    var upCaseData=e.target.value
    if (props.from == 'payment') {
      var searchedVal =   paymentListCopy.filter(val => val.name.includes(upCaseData.toLowerCase()))
      setPaymantList(searchedVal)
      if(searchedVal.length == 0){
        setResult(true)
      }else{
          setResult(false)
      }
    }else{
      var searchedVal =   userCopy.filter(val => val.name.includes(upCaseData.toLowerCase()))
          setUsers(searchedVal)

      if(e.target.value.length == 0){
        getUsers()
          setUsers(users)
      }
      if(searchedVal.length == 0){
          setResult(true)
      }else{
          setResult(false)
      }
    }
  }

  const handleCustomPopup = (type,show,user)=>{
    setShowType(type)
    setShowCustomPopup(show)
    setChooseUser(JSON.stringify(user))
  }

  const handleEdit=(user)=>{
    setEdit(user)
    setShowRegister(true)
  }
  const handleDelete=async()=>{
    try{
      deleteChecked.forEach(async(userId)=>{
        const docRef = doc(database, localStorage.getItem("uid"),'mT', 'list', userId)
        await deleteDoc(docRef)
        const getChecked = document.querySelectorAll('input[type="checkbox"]')
        getChecked.forEach(unCheck=>unCheck.checked = false)
        setDeleteChecked([])
        setDeleteUser(false)
        getUsers()
      })
    }catch(e){
      console.log("error",e)
    }
  }

  const handleCheck = (id,details) =>{
      let isExistingUserIndex = deleteChecked.indexOf(id)   
      let exportList = exportChecked.map(val=>val.id)
      let isExistingExport = exportList.indexOf(id)

      if(isExistingUserIndex !== -1 || isExistingExport !== -1){
        // delete list
        const newDeleteList = [...deleteChecked]
        newDeleteList.splice(isExistingUserIndex,1)
        setDeleteChecked(newDeleteList)
        // export list
        const newExportList = [...exportChecked]
        newExportList.splice(isExistingExport,1)
        setExportChecked(newExportList)
      }else{
        setDeleteChecked(pre=>[...pre,id])
        let data =  props.from == 'trainer'?
        {
          id:details.id,
          name:details.name,
          phone:details.phone,
          email:details.email,
          jData:details.jData,
        }:{
          id:details.id,
          name:details.name,
          phone:details.phone,
          email:details.email,
          jData:details.jData,
          tAmount:details.tAmount,
          pAmount:details.pAmount,
        }
        setExportChecked([...exportChecked,data])
      }
  }

  const  handleFilter = (filterType,jDate) =>{
    setSearchData('')
    let type = jDate == 'drop'?  filterType.target.value:filterType;
    if(jDate == 'drop'){
      setSelectedOption(type)
    }
    if(type == 'joinedDate' && jDate == 'drop'){
      setShowDate(true)
      getUsers()
      setUsers(users)
    }else if(type == 'joinedDate'){
      setShowDate(true)
    }else{
      setShowDate(false)
    }
    const getChecked = document.querySelectorAll('input[type="checkbox"]')
    getChecked.forEach(unCheck=>unCheck.checked = false)
    setDeleteChecked([])
    setExportChecked([])
    setActivefilter(type)
    const aT = userCopy.filter((doc) => doc.type === "T" && doc.isActiveTrainer === "Y")
    const iT = userCopy.filter((doc) => doc.type === "T" && doc.isActiveTrainer === "N")
    const tT = userCopy.filter((doc) => doc.type === "T")

    const aM = userCopy.filter((doc) => doc.type === "M" && doc.category === "N")
    const cM = userCopy.filter((doc) => doc.type === "M" && doc.category === "C")
    const tM = userCopy.filter((doc) => doc.type === "M")
    const tC = userCopy.filter((doc) => doc.type === "C")

    setUsers([])
    if(type == 'new'){
      setUsers(aM)
    }else if(type == 'completed'){
      setUsers(cM)
    }else if(type == 'pending'){
      userCopy.forEach(val=>{
        if(val.type === "M" && val.category == "N"){
          let pending = JSON.parse(val.pAmount)
          let total = JSON.parse(val.tAmount)
          if(pending < total){
            setUsers(data=>[...data,val]) 
          }
        }
      })
    }else if(type == 'nodue'){
      userCopy.forEach(val=>{
        if(val.type === "M" && val.category == "N"){
          let pending = JSON.parse(val.pAmount)
          let total = JSON.parse(val.tAmount)
          if(pending == total){
            setUsers(data=>[...data,val]) 
          }
        }
      })
    }else if(type == 'all'){
        getUsers()
        setUsers(users)
    }if(type == 'active'){
      setUsers(aT)
    }if(type == 'inActive'){
      setUsers(iT)
    }else if(type == 'joinedDate'){
      if(jDate == ''){
        getUsers()
        setUsers(users)
      }else{
        const currentDate = new Date(jDate);
        const currentMonth = currentDate.getMonth()+1
        const currentYr = currentDate.getFullYear()
        userCopy.forEach(val=>{
          let splitDate = val.jData.split('-')
          if(splitDate[0] == currentYr){
            if(splitDate[1] == currentMonth){
              setUsers(data=>[...data,val])
            }
          }
        })
      }
    }
  }

  useEffect(()=>{
    if(users.length == 0){
      setResult(true)
    }
  },[users])

const handlePaymentFilter = (fDate)=>{
    if(fDate == ''){
      setPaymantList(paymentListCopy)
    }else{
      setPaymantList([])
      const currentDate = new Date(fDate);
      const currentMonth = currentDate.getMonth()+1
      const currentYr = currentDate.getFullYear()
      paymentListCopy.forEach(val=>{
        let splitDate = val.paidDate.split('-')
        if(splitDate[0] == currentYr){
          if(splitDate[1] == currentMonth){
            setPaymantList(data=>[...data,val])
          }
        }
      })
    }
}

useEffect(()=>{
  if(props.from !== 'payment'){
    getUsers()
  }
  setActivefilter('all')
},[showRegister,props.reRenderData])

const HandleExport = () => {
  const doc = new jsPDF();
  let cName = localStorage.getItem('companyName');
  const title = `${cName} ${props.from == 'trainer'?'trainer':'Member'} list`;
  const padding = 10;
  const textWidth = doc.getTextWidth(title);
  const center = (doc.internal.pageSize.width / 2) - (textWidth / 2);
  doc.setFont('helvetica', 'bold'); // Set font weight to bold
  doc.setTextColor('#2a167a'); // Set text color to red
  doc.text(title, center, padding);
  let pdfHeader = props.from == 'trainer' ?
  ['No', 'Name', 'Phone', 'Email', 'Joined']:
  ['No', 'Name', 'Phone', 'Email', 'Joined', 'Tot_amt', 'Paid_amt']
  doc.autoTable({
    head: [pdfHeader],
    body: exportChecked.map((row,i) => [i+1, row.name, row.phone, row.email, row.jData, row.tAmount, row.pAmount]),
    columnStyles:  props.from == 'trainer'?'':{
      0: { cellWidth: 10 }, // ID column
      1: { cellWidth: 35 }, // Name column
      2: { cellWidth: 25 }, // Phone column
      3: { cellWidth: 45 }, // Email column
      4: { cellWidth: 30 }, // Joined column
      5: { cellWidth: 20 }, // Tot_amt column
      6: { cellWidth: 20 }  // Paid_amt column
    },
    headStyles: {
      fillColor: '#2a167a', // Set the color of the header
      textColor: 'white' // Set the text color of the header
  },
  });
  doc.save(`${title}.pdf`);
};

const handleSetDefult = ()=>{
  setSelectedOption('all')
  setShowDate(false)
  setActivefilter('all')
}

  return (
    <div>
        <div className='mainDataTable'>
          <div style={props.from == "payment"?{height: 'calc(100vh - 88vh)'}:{}} className='filterData'>
            <div  className='filterAndSearch'>       
            {
              props.from == 'trainer'?
              <div>
                <div className='userDataFilterTrainnee'>
                  <div className={activefilter == 'all'?'activefilter':''} onClick={()=>handleFilter('all')}>All {activefilter == 'all'?('('+users?.length+')'):''}</div>
                  <div className={activefilter == 'active'?'activefilter':''} onClick={()=>handleFilter('active')}>Active {activefilter == 'active'?('('+users?.length+')'):''}</div>
                  <div className={activefilter == 'inActive'?'activefilter':''} onClick={()=>handleFilter('inActive')}>InActive {activefilter == 'inActive'?('('+users?.length+')'):''}</div>
                  <div><input className='filterDate' onChange={(e)=>handleFilter('joinedDate',e.target.value)} type='month' placeholder="Select Date" /><br/></div>
                </div>
                <div className='mobileFilter'>
                  <div>
                    <select  value={selectedOption} onFocus={()=>setSearchData('')} onChange={(e)=>handleFilter(e,'drop')}>
                      <option value='all'>All</option>
                      <option value='active'>Active</option>
                      <option value='inActive'>InActive</option>
                      <option value='joinedDate'>Joined Date</option>
                    </select>
                  </div>
                  {
                    showDate?
                    <div><input  className='filterDate' onChange={(e)=>handleFilter('joinedDate',e.target.value)} type='month' /></div>:<div></div>
                  }
                </div>
              </div>
              :
              props.from == 'member' ?
              <div>
                <div className='userDataFilter'>
                  <div className={activefilter == 'all'?'activefilter':''} onClick={()=>handleFilter('all')}>All {activefilter == 'all'?('('+users?.length+')'):''}</div>
                  <div className={activefilter == 'pending'?'activefilter':''} onClick={()=>handleFilter('pending')}>Due {activefilter == 'pending'?('('+users?.length+')'):''}</div>
                  <div className={activefilter == 'nodue'?'activefilter':''} onClick={()=>handleFilter('nodue')}>No Due {activefilter == 'nodue'?('('+users?.length+')'):''}</div>
                  <div className={activefilter == 'new'?'activefilter':''} onClick={()=>handleFilter('new')}>New {activefilter == 'new'?('('+users?.length+')'):''}</div>
                  <div className={activefilter == 'completed'?'activefilter':''} onClick={()=>handleFilter('completed')}>Completed {activefilter == 'completed'?('('+users?.length+')'):''}</div>
                  <div><input className='filterDate' onChange={(e)=>handleFilter('joinedDate',e.target.value)} type='month' /><br/></div>
                </div>
                <div className='mobileFilter'>
                  <div>
                    <select onFocus={()=>setSearchData('')} name='filterList' value={selectedOption} onChange={(e)=>handleFilter(e,'drop')}>
                      <option value='all'>All</option>
                      <option value='pending'>Due</option>
                      <option value='nodue'>No Due</option>
                      <option value='new'>New</option>
                      <option value='completed'>Completed</option>
                      <option value='joinedDate'>Joined Date</option>
                    </select>
                  </div>
                  {
                    showDate?
                    <div><input className='filterDate' onChange={(e)=>handleFilter('joinedDate',e.target.value)} type='month' /></div>:<div></div>
                  }
                </div>
              </div>
              :
              <div>
                <div><input className='paymentDateFilter' onChange={(e)=>props.from == 'checkIn'?handleFilter('joinedDate',e.target.value):handlePaymentFilter(e.target.value)} type='month' /><br/></div>
              </div>
            }     
              <div className={props.from == 'payment'?'mobileSpacing endSearch':'endSearch'}>
                  <input value={searchData} name="search" onFocus={handleSetDefult} onChange={(e)=>handleSearch(e)} className="search" placeholder="Search by Name" autoComplete="off" />
              </div>
            </div>
            {
            props.from !== 'payment'?
              <div className='recordAction'>
                {
                  deleteChecked.length>0?
                <div>
                  <div  onClick={()=>setDeleteUser(true)}><span className='deletebtn'>Delete {('('+deleteChecked?.length+')')}</span></div>
                </div>
                :''
                }
                {
                  exportChecked.length>0 && deleteChecked.length>0?
                  <div>
                    <div onClick={HandleExport}><span className='deletebtn'>Export {('('+exportChecked?.length+')')}</span></div>
                  </div>:
                  ""
                }
              </div>:''
            }
          </div>
          <div className='dataTable'>
          {
            props.from == 'trainer' || props.from == 'member'?
          <div className='userList'>
              <div className={props.from == 'trainer'?'customTableTrainneeTitle tableheader':'customTableTitle memberTableTitleGrid tableheader'} style={{textAlign:'center'}}>
                  <div></div>
                  <div className='customTableHeader'>Name</div>
                  <div className='customTableHeader'>Email</div>
                  <div className='customTableHeader'>age</div>
                  <div className='customTableHeader'>phone</div>
                  <div className='customTableHeader'>Address</div>
                  <div className='customTableHeader'>Date of Join</div>
                  { props.from == 'trainer'?'': <div className='customTableHeader'>Total Amt</div>}
                  { props.from == 'trainer'?'': <div className='customTableHeader'>Paid Amt</div> }
                  <div className='customTableHeader'>Status</div>
                  <div className='customTableHeader'>Action</div>
              </div>
            <div className='customTableBody' style={users.length == 0?{display:'flex',alignItems:'center',justifyContent:'center'}:{}}>
              <div>
              {users.length>0?
                    users.map((val,i)=><div className={props.from == 'trainer'?'customTrainnerContent':'customTableContent memberTableTitleGrid'} key={i}>
                      <div><div></div><span className='deleteCheckBox'><input type='checkbox' className='userListCheckbox' onChange={()=>handleCheck(val.id,val)} /></span></div>
                      <div><span className='labelForMobile'>Name :</span><span>{val.name}</span></div>
                      <div><span className='labelForMobile'>Email :</span><span>{val.email}</span></div>
                      <div><span className='labelForMobile'>Age :</span><span>{val.age}</span></div>
                      <div><span className='labelForMobile'>Phone :</span><span>{val.phone}</span></div>
                      <div><span className='labelForMobile'>Address :</span><span>{val.address}</span></div>
                      <div><span className='labelForMobile'>Joined :</span><span>{val.jData}</span></div>
                      { props.from == 'trainer'?'': <div><span className='labelForMobile'>Total Amt :</span><span>{val.tAmount}</span></div> }
                      { props.from == 'trainer'?'': <div><span className='labelForMobile'>Paid Amt :</span><span>{val.pAmount}</span></div> }
                      <div width="5%"><span className='labelForMobile'>Status :</span><span>{val.type == 'M' ? (val.category == 'N'? 'New' : 'Completed'):(val.isActiveTrainer == 'Y'? 'Active':'InActive') }</span></div>
                      <div className='actionButton'>
                      { props.from == 'trainer'?'':<span><button className='textbtn' disabled={val.tAmount == val.pAmount} onClick={()=>handleCustomPopup('pay',true,val)}>Pay</button></span> }
                      <span><button className='textbtn' onClick={()=>handleCustomPopup('move',true,val)}>Move</button></span>
                      <span><button className='textbtn' onClick={()=>handleEdit(val)}>Edit</button></span>
                      </div>
                    </div>
                    ):result ? <span className='noResult'>No Result</span> : <Loading/>
                  }
              </div>
            </div>
          </div>
          :
          (
            props.from =='checkIn'?
            <div>
              <div className='userList'>
              <div className='customTableTitle checkInTableTitleGrid tableheader' style={{textAlign:'center'}}>
                  <div></div>
                  <div className='customTableHeader'>Name</div>
                  <div className='customTableHeader'>Email</div>
                  <div className='customTableHeader'>age</div>
                  <div className='customTableHeader'>phone</div>
                  <div className='customTableHeader'>Address</div>
                  <div className='customTableHeader'>Date of Join</div>
                  <div className='customTableHeader'>Status</div>
                  <div className='customTableHeader'>Action</div>
              </div>
            <div className='customTableBody' style={users.length == 0?{display:'flex',alignItems:'center',justifyContent:'center'}:{}}>
              <div>
              {users.length>0?
                    users.map((val,i)=><div className='customTableContent checkInTableTitleGrid' key={i}>
                      <div><div></div><span className='deleteCheckBox'><input type='checkbox' className='userListCheckbox' onChange={()=>handleCheck(val.id,val)} /></span></div>
                      <div><span className='labelForMobile'>Name :</span><span>{val.name}</span></div>
                      <div><span className='labelForMobile'>Email :</span><span>{val.email}</span></div>
                      <div><span className='labelForMobile'>Age :</span><span>{val.age}</span></div>
                      <div><span className='labelForMobile'>Phone :</span><span>{val.phone}</span></div>
                      <div><span className='labelForMobile'>Address :</span><span>{val.address}</span></div>
                      <div><span className='labelForMobile'>Joined :</span><span>{val.jData}</span></div>
                      <div width="5%"><span className='labelForMobile'>Status :</span><span>{val.type == 'M' ? (val.category == 'N'? 'New' : 'Completed'):props.from =='checkIn'?"Not Member":(val.isActiveTrainer == 'Y'? 'Active':'InActive') }</span></div>
                      <div className='actionButton'>
                      <span><button className='textbtn' onClick={()=>handleCustomPopup('moveCheckIn',true,val)}>Move</button></span>
                      <span><button className='textbtn' onClick={()=>handleEdit(val)}>Edit</button></span>
                      </div>
                    </div>
                    ):result ? <span className='noResult'>No Result</span> : <Loading/>
                  }
              </div>
            </div>
          </div>
            </div>
            :
            <div className='userList'>
              <div className='customTablePayment tableheader' style={{textAlign:'center',marginTop:'10px'}}>
                  <div className='customTableHeader'>Name</div>
                  <div className='customTableHeader'>Email</div>
                  <div className='customTableHeader'>phone</div>
                  <div className='customTableHeader'>Paid date</div>
                  <div className='customTableHeader'>Paid Amt</div>
              </div>
            <div className='paymentTableBody' style={paymentList.length == 0?{display:'flex',alignItems:'center',justifyContent:'center'}:{}}>
              <div>
              {paymentList.length>0?
                    paymentList.map((val,i)=><div className='customPaymentContent' key={i}>
                      <div> <span className='labelForMobile'>Name :</span><span>{val.name}</span></div>
                      <div> <span className='labelForMobile'>Email :</span><span>{val.email}</span></div>
                      <div> <span className='labelForMobile'>Phone :</span><span>{val.phone}</span></div>
                      <div> <span className='labelForMobile'>Paid Dt :</span><span>{val.paidDate}</span></div>
                      <div> <span className='labelForMobile'>Paid Amt :</span><span>{val.amount}</span></div>
                    </div>
                    ):result ? <span className='noResult'>No Result</span> : <Loading/>
                  }
              </div>
            </div>
          </div>
          )
        }
          </div>
        </div>
        {showPay?
          <div>
            <UpdatePayAndMove from={props.from} userVal={chooseUser} hide={handleCustomPopup} type={showType} />
          </div>
          :""
        }
        {
          showRegister?<RegistrationPopUp action="edit" from={props.from} hide={setShowRegister} userData={edit} />:''
        }
        {
          deleteUser?
          <div>
            <div className='deleteModalOverley'>
              <div className='deleteModal'>
                  <div>
                    <span className='deleteModelContent'>Do you want to delete this?</span>
                    <div className='deleteModalBtn'>
                      <button onClick={handleDelete}>Yes</button>
                      <button onClick={()=>setDeleteUser(false)}>Cancel</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>:''
        }
    </div>
  )
}

export default UserTable