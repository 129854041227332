import React, { useEffect, useState } from 'react'
import './RegisterForm.scss'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { auth, database } from '../../FirebaseConfig/config'
import close from '../../Assets/close.png'
import CommonAlert from '../CommonAlert/CommonAlert'


function RegistrationPopUp(props) {
  const [emi,setEmi] = useState(false)
  const [interNet,setInterNet] = useState(false)
  const [emailValidation,setEmailValidation] = useState(false)
  const [uid,setUid] = useState('')
  let valueData = props.userData;
  const [values, setValues] = useState({
    name: valueData?valueData.name: '',
    age: valueData?valueData.age: '',
    email: valueData?valueData.email: '',
    phone: valueData?valueData.phone: '',
    address: valueData?valueData.address: '',
    jData: valueData?valueData.jData: '',
    tAmount: valueData?valueData.tAmount: '',
    pAmount: valueData?valueData.pAmount: '',
    category: valueData?valueData.category: '',
    type: valueData?valueData.type: '',
    isEmi: valueData?valueData.isEmi: '',
    isActiveTrainer: valueData?valueData.isActiveTrainer: '',
  });

  useEffect(()=>{
    setEmi(values.isEmi)
  },[values.isEmi])

  useEffect(()=>{
      auth.onAuthStateChanged((existingUser)=>{
        try {
          setUid(existingUser.uid?existingUser.uid:existingUser.providerData[0].uid)
        } catch (error) {
          console.log(error)
        }
      })
  },[])

  const handleRegister = async(e) =>{
    e.preventDefault()
    // mT - trainee and member 
    const mTCol = doc(database,uid,'mT')
    const valueDb = collection(mTCol,'list')

        let updateVal;
        if(valueData){
          const mTCol = doc(database,localStorage.getItem("uid"),'mT')
          const valueDb = collection(mTCol,'list')
          updateVal =  doc(valueDb, valueData.id);
      }
      if (navigator.onLine) {
            try{
              props.hide(false)
              await (valueData?updateDoc:addDoc)(valueData?updateVal:valueDb,{
                  name:e.target.name.value.toLowerCase(),
                  age:e.target.age.value,
                  email:e.target.email.value,
                  phone:e.target.phone.value,
                  address:e.target.address.value,
                  jData:e.target.jData.value,
                  tAmount:props.from == 'member' ?(e.target.tAmount.value) :props.from =='checkIn'?0:'',
                  isEmi:props.from == 'member' || props.from =='checkIn' ?(emi? true:false) : '',
                  pAmount:props.from == 'member' ?(emi?( e.target.pAmount.value?e.target.pAmount.value:0):e.target.tAmount.value) :props.from =='checkIn'?0:'',
                  category: props.from == 'member' || props.from =='checkIn' ? 'N': '', //N - New, C - Completed
                  isActiveTrainer: props.from == 'trainer' ? 'Y' : '', // Y - Yes, N- No 
                  type:props.from == 'trainer' ? 'T' : props.from == 'checkIn'? 'C':'M', // T - Trainer M - Member C - Check-in
                  payments:props.from == 'member' ? emi?[{amount:e.target.pAmount.value,paidDate:JSON.stringify(e.target.jData.value)}]:[{amount:e.target.tAmount.value,paidDate:JSON.stringify(e.target.jData.value)}]:props.from =='checkIn'?[{amount:0,paidDate:JSON.stringify(e.target.jData.value)}]:'', 
                  created:new Date()
                })
            }catch(e){
                console.log(e,"error")
            }
          }else {
            setInterNet(true)
            console.log('No internet connection.');
          }
  }


  const handleInput =(e)=>{
    const { name, value } = e.target;
    if(name == 'email'){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValidation(emailRegex.test(value));
    }
    setValues({
      ...values,
      [name]: value,
    });
  }
  useEffect(()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValidation(emailRegex.test(values.email));
  },[])

  return (
    <div>
      <div className='registrationPopup'>
        <div className='registrationForm'>
          <button onClick={()=>props.hide(false)} className='cloaseButton'><img src={close} height="15px" alt='close registration popup' /></button>
          <form onSubmit={(e)=>handleRegister(e)}>
            <div className='titleContainer'><span className='formTitle'>{props.action=='edit'?'Edit':'Add'} {props.from == 'member'?'Member':props.from == 'checkIn'?'Leads':'Trainnee'}</span></div><br/>
            <input required value={values.name}  onChange={handleInput} name='name' placeholder='Name' /><br/>
            <input required value={values.age}  onChange={handleInput} name='age' placeholder='Age' type='number' />
            <div className='startContainer'>
              <div className='emailInput'>
                {!emailValidation && values.email.length > 0 ?<span style={{ color: 'red' }}>Invalid email</span> : ''}<br/>
                <input required value={values.email}  onChange={handleInput} name='email' placeholder='Email' /><br/>
              </div>
            </div>
            <input type='number' value={values.phone}  onChange={handleInput} name='phone' placeholder='Phone number' /><br/>
            <textarea required value={values.address}  onChange={handleInput} name='address' placeholder='Address' />
            <div className='startContainer'>
              <div style={{textAlign:'start'}}>
                <span style={{color:"var(--active-title)"}}>Joining Date</span><br/>
                <input required value={values.jData}  onChange={handleInput} type='date' name='jData'/>
              </div>
            </div>
            {
              props.from == 'checkIn'?'':
            
             props.from == 'trainer'?'': <div><input required type='number' value={values.tAmount}  onChange={handleInput} name='tAmount' placeholder='Total amount' /><br/></div>}
            {
              props.from == 'checkIn'?'':
              props.from == 'member' || props.from == 'checkIn' ?
            <div className='toggleButton'>
              <div style={{width:'257px',display:'flex',alignItems:'center'}}>
              <div className='emiToggle' onClick={()=>setEmi(!emi)}>
                <div className={emi  ?'onToggle':'offToggle'} ></div>
              </div>
                <span className='emiLable'>If Emi is available ?</span>
              </div>
            </div>
            :''}
            <div className='paidAmount'>
              {
                emi?
                <input type='number' max={values.tAmount} className={valueData? 'disable':''}  value={values.pAmount}  onChange={handleInput} name='pAmount' placeholder='Pay initial amount' />:''
              }
            </div>
            <br/><br/>
            <button style={props.from == 'member'?{cursor:'pointer'}:{marginBottom:'8px',cursor:'pointer'}} disabled={!emailValidation && values.email.length > 0}>{valueData?"Update":"Register"}</button>
          </form>
        </div>
      </div>
      {
          interNet?
          <CommonAlert msg="No Internet" hide={setInterNet} />:''
      }
    </div>
  )
}

export default RegistrationPopUp